export default [
    '#a84854', '#b2bec4', '#edbc2f', '#899c83', '#4ec426', '#17ba6d', '#1f7cea', '#3d68aa', '#40ad58', '#4129d0', '#64f77c', '#20c7a7', '#caa2d4', '#961aa2',
    '#a21ee3', '#38219b', '#183b40', '#3d4fd3', '#bbd4f4', '#bf7a8d', '#e44dfa', '#88660d', '#2d50c2', '#6ccfff', '#97ac33', '#47afd1', '#42834e', '#4dcee6',
    '#9f688c', '#7dfa7a', '#878a58', '#a46b10', '#e01a80', '#aed9bb', '#f35bcf', '#699ce1', '#3eaa32', '#cf3e78', '#52bbbe', '#e0b9da', '#cf3e38', '#4c1818',
    '#dbfea3', '#79d9a7', '#5c3ceb', '#bc4a5a', '#68f6c0', '#2b8675', '#208508', '#de34ec', '#3ddc8f', '#462077', '#463ab2', '#cd624a', '#353713', '#4b0e57',
    '#525ca8', '#5aaf66', '#13288c', '#9c8b11', '#61f2bb', '#9c484b', '#c710c7', '#d6c222', '#d1a6fe', '#4279d6', '#d33471', '#3eb25f', '#1d2735', '#37fd1b',
    '#46b77c', '#9aec01', '#26dccb', '#dc4c3f', '#8163a0', '#210bd8', '#2bd55e', '#40056a', '#3bbfac', '#ff0972', '#aceb84', '#973cde', '#2e6cff', '#927323',
    '#f5d2e9', '#99145d', '#500db0', '#46be9d', '#e5092a', '#71d732', '#52f221', '#b9453e', '#c0740f', '#6177a3', '#390a22', '#e8b212', '#827308', '#433546',
    '#11d2ef', '#dd4914', '#623fba', '#bea996', '#ca09cb', '#367f96', '#e34703', '#a1c459', '#6e36a7', '#b9a232', '#74a752', '#8bd5a6', '#66e328', '#52e1d1',
    '#5cf238', '#acdb8f', '#4cb06f', '#c1e0d9', '#aed4e9', '#2d66e4', '#1379ad', '#f36688', '#bd3059', '#e7b4a6', '#87c77d', '#c47086', '#fde68c', '#7a284c',
    '#1daec7', '#66c621', '#c4ee24', '#2dd663', '#572768', '#df7696', '#26cf19', '#fa8e61', '#6ffc3b', '#c044fb', '#faee25', '#1eab40', '#a6a97b', '#736799',
    '#58ac96', '#d07977', '#feca51', '#fb143c', '#ddbb66', '#4c7c05', '#650673', '#85019e', '#7d01f8', '#8a7f22', '#da3acd', '#e3d7e0', '#442838', '#3fe0be',
    '#bfe15a', '#eedf3d', '#9afe6c', '#838ac1', '#40c648', '#129d5b', '#476ac3', '#738737', '#ba3170', '#8d5fef', '#2fdaae', '#ee224f', '#a99f68', '#5447af',
    '#eaff6f', '#c8d6b1', '#969566', '#6d0001', '#522c23', '#6b3cf1', '#53919e', '#a37652', '#732220', '#9034a7', '#336981', '#3436a2', '#2742dc', '#79067c',
    '#eee49c', '#f76abd', '#3090a8', '#6ecbea', '#a77205', '#e32c67', '#e9f964', '#d63efa', '#22aa8a', '#d61c4f', '#9f85bd', '#177926', '#e8bfcd', '#6f35f8',
    '#f206b7', '#73f021', '#66350b', '#a85106'
]
