<template>
  <b-row>
    <b-col
        v-if="charts.pieChart.isActive"
        :cols="12"
        :md="columnSize"
        class="mb-md-0 mb-2 h-100"
    >
      <loading :show="isLoading && !isLoadingPage">
        <pie-chart
            :option="charts.pieChart.option"
            :title="title(charts.pieChart.name)"
        />
      </loading>
    </b-col>

    <b-col
        v-if="charts.barChart2.isActive"
        :cols="12"
        :md="columnSize"
        class="mb-md-0 mb-2 h-100"
    >
      <loading :show="isLoading && !isLoadingPage">
        <bar-chart
            :option="charts.barChart2.option"
            :title="title(charts.barChart2.name)"
            :report-name="name()"
        />
      </loading>
    </b-col>

    <b-col
        v-if="charts.barChart.isActive"
        :cols="12"
        :md="columnSize"
        class="mb-md-0 mb-2 h-100"
    >
      <loading :show="isLoading && !isLoadingPage">
        <bar-chart
            :option="charts.barChart.option"
            :title="title(charts.barChart.name)"
            :report-name="name()"
        />
      </loading>
    </b-col>

    <b-col
        v-if="charts.multiLineChart.isActive"
        :cols="12"
        :md="columnSize"
        class="mb-md-0 mb-2 h-100"
    >
      <loading :show="isLoading && !isLoadingPage">
        <apex-chart-line
            :title="title(charts.multiLineChart.name)"
            :option="charts.multiLineChart.option"
            :report-name="name()"
            @refresh="refresh"
        />
      </loading>
    </b-col>
  </b-row>
</template>

<script>

import PieChart from "@/views/reports/components/charts/PieChart.vue";
import BarChart from "@/views/reports/components/charts/BarChart.vue";
import ReportDataServiceFactory from "@/views/reports/services/ReportDataServiceFactory";
import ApexChartLine from "@/views/reports/components/charts/ApexChartLine.vue";

export default {
  components: {
    PieChart,
    BarChart,
    ApexChartLine,
  },
  props: {
    isLoadingPage: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Object,
      default: null,
    },
    reportName: {
      type: [String],
      required: false,
      default: null,
    },
    constraint: {
      type: Object,
      default: () => {
        return {}
      },
    }
  },
  data() {
    return {
      reportDataService: ReportDataServiceFactory.create(this.reportName),
      columnSize: 6,
      defaultChart: {
        isActive: false,
        option: {
          series: [],
          labels: [],
          allowedIntervals: [],
          chartInterval: '',
          subCharts: [],
        },
        title: "",
      },
      charts: {
        pieChart: {},
        barChart: {},
        barChart2: {},
        multiLineChart: {},
      },
    }
  },
  computed: {
    title() {
      return chartName => this.$i18n.t(`reports.chart.${this.name()}.${chartName}`);
    }
  },
  watch: {
    filter(_new, _old) {
      if (!_.isEqual(_new, _old)) {
        this.getData()
      }
    },
  },
  created() {
    this.reportDataService.handleConstraint(this.constraint)
    this.initChart()
    this.columnSize = this.getSizeColumnOfCharts(this.reportDataService.getCharts())
  },
  methods: {
    getSizeColumnOfCharts(charts){
      const length = charts.length
      if(length === 3) return 4
      if(length === 2) return 6
      return 12
    },
    initChart() {
      this.reportDataService.getCharts().forEach(item => {
        const chart = {
          option: item.defaultOption,
          isActive: item.isActive,
          name: item.name,
        }
        this.charts[item.type] = JSON.parse(JSON.stringify(chart))
      })
    },
    refresh(param) {
      this.reportDataService.update(param)
      this.getData()
    },
    name() {
      return this.reportName ? this.reportName : this.$router.currentRoute.name
    },
    getData() {
      if (!this.isLoading && !this.isLoadingPage) {
        this.changeLoadingChart(true)
        this.reportDataService.setFilter(this.filter)
        const params = this.reportDataService.getChartParams()
        const id = this.reportDataService.getChartId()
        this.reportDataService.getChartData(id, params).then(res => {
          this.reportDataService.handleChartResponse(res)
          const chartsReport = this.reportDataService.getCharts()
          this.columnSize = this.getSizeColumnOfCharts(chartsReport)
          chartsReport.forEach(chart => {
            this.mapping(chart)
          })
          this.changeLoadingChart(false)
        })
      }
    },
    changeLoadingChart(status) {
      this.$emit('update:is-loading', status)
    },
    mapping(chart) {
      Object.keys(this.charts).forEach(key => {
        if (key === chart.type) {
          this.charts[key] = JSON.parse(JSON.stringify(chart))
        }
      })
    }
  }
}
</script>
