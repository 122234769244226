<template>
  <b-card no-body>
    <b-card-header>
      <h4 class="card-title mb-50">
        <span>{{ title }}</span>
      </h4>
      <b-button-group size="sm">
        <b-button
            v-if="isShowFilter('D')"
            v-b-tooltip.hover.top="$i18n.t('reports.chart.interval.daily')"
            variant="outline-primary"
            :class="isActiveBtn('D')"
            @click="changeChartInterval('D')"
        >D
        </b-button>
        <b-button
            v-if="isShowFilter('W')"
            v-b-tooltip.hover.top="$i18n.t('reports.chart.interval.weekly')"
            variant="outline-primary"
            :class="isActiveBtn('W')"
            @click="changeChartInterval('W')"
        >W
        </b-button>
        <b-button
            v-if="isShowFilter('M')"
            v-b-tooltip.hover.top="$i18n.t('reports.chart.interval.monthly')"
            variant="outline-primary"
            :class="isActiveBtn('M')"
            @click="changeChartInterval('M')"
        >M
        </b-button>
        <b-button
            v-if="isShowFilter('Y')"
            v-b-tooltip.hover.top="$i18n.t('reports.chart.interval.yearly')"
            variant="outline-primary"
            :class="isActiveBtn('Y')"
            @click="changeChartInterval('Y')"
        >Y
        </b-button>
      </b-button-group>
    </b-card-header>
    <b-card-body>
      <!-- echart -->
      <vue-apex-charts
          ref="chart"
          type="line"
          height="400"
          :options="lineChart.chartOptions"
          :series="lineChart.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import {$themeColors} from '@themeConfig'
import {VBTooltip,} from 'bootstrap-vue'

export default {
  components: {
    VueApexCharts
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    option: {
      type: [Object],
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    reportName: {
      type: String,
      default: '',
    },
  },
  computed: {
    xAxisTitle() {
      return this.$i18n.t(`reports.chart.${this.reportName}.x_axis_title`)
    },
    charts(){
      return this.option.subCharts.map(item => {
        return {
          ...item,
          name: this.$i18n.t(`reports.chart.${this.reportName}.${item.seriesKey}.title`),
          y_axis_title: this.$i18n.t(`reports.chart.${this.reportName}.${item.seriesKey}.y_axis_title`)
        }
      })
    },
    lineChart() {
      const data = {
        series: [],
        chartOptions: {
          chart: {
            zoom: {
              enabled: this.option.zoom,
            },
            toolbar: {
              show: this.option.toolbar,
            },
          },
          markers: {
            strokeWidth: 1,
            strokeOpacity: 0.5,
            strokeColors: [$themeColors.light],
            colors: [$themeColors.warning],
          },
          colors: [],
          dataLabels: {
            enabled: this.option.dataLabels,
          },
          stroke: {
            curve: 'straight',
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          xaxis: {
            categories: this.option.xAxisData,
            title: {
              text: this.xAxisTitle,
              margin: 10,
              style: {
                fontSize: '15px',
                color: '#5e5873',
              }
            }
          },
          yaxis: [],
        }
      }
      let oppositeValue = false
      this.charts.forEach((item, index) => {
        const yAxis = {
          opposite: oppositeValue,
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
            color: item.color
          },
          labels: {
            style: {
              colors: item.color
            },
            formatter: (val) => {
              return this.formatterHelper(val, item.format, {maximumFractionDigits: 0});
            }
          },
          title: {
            text: item.y_axis_title,
            margin: 10,
            style: {
              fontSize: '15pxv',
              color: item.color,
            }
          },
        }
        data.chartOptions.yaxis.push(yAxis)
        data.chartOptions.colors.push(item.color)
        if(this.option.series[index]){
          data.series.push({
            name: item.name,
            data: this.option.series[index]
          })
        }
        oppositeValue = true
      })
      return data
    },
  },
  methods: {
    isShowFilter(type) {
      return this.option.allowedIntervals.includes(type)
    },
    changeChartInterval(type) {
      this.$emit('refresh', {chartInterval: type})
    },
    isActiveBtn(type) {
      return (this.option.chartInterval === type) ? 'active' : ''
    }
  },
}
</script>
