<template>
  <b-card>
    <b-card-header>
      <h4 class="card-title mb-50">
        <span>{{ title }}</span>
      </h4>
    </b-card-header>
    <b-card-body>
      <div style="overflow: scroll; height: 380px;">
        <vue-apex-charts
            type="bar"
            :height="(height > 350) ? height : 350"
            :options="barChart.chartOptions"
            :series="barChart.series"
        />
      </div>
    </b-card-body>
  </b-card>
</template>

<script>

import VueApexCharts from 'vue-apexcharts'
import getBrand from "@/config/branding-config";

export default {
  components: {
    VueApexCharts
  },
  props: {
    option: {
      type: [Object],
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    reportName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      colors: [getBrand().primaryColor, getBrand().secondaryColor]
    }
  },
  computed: {
    height(){
      return (this.option.series.length > 30) ? this.option.series.length * 18 : this.option.series.length * 25
    },
    seriesName() {
      return this.$i18n.t(`reports.chart.${this.reportName}.seriesName`)
    },
    barChart() {
      return {
        series: [{
          name: this.seriesName,
          data: this.option.series
        }],
        chartOptions: {
          colors: this.colors,
          plotOptions: {
            bar: {
              horizontal: true,
            }
          },
          dataLabels: {
            enabled: true,
            formatter(value) {
              return `${value.toFixed(2)}%`;
            }
          },
          tooltip: {
            y: {
              formatter(value) {
                return `${value.toFixed(2)}%`;
              }
            }
          },
          xaxis: {
            categories: this.option.labels,
            labels: {
              formatter(value) {
                return `${value.toFixed(2)}%`;
              }
            }
          },
        }
      }
    }
  },
  methods: {}
}
</script>
