<template>
  <b-card>
    <b-card-header>
      <h4 class="card-title mb-50">
        <span>{{ title }}</span>
      </h4>
    </b-card-header>
    <b-card-body>
      <vue-apex-charts
          v-if="option.series.length > 0"
          ref="chart"
          type="pie"
          :height="370"
          :options="pieChart.chartOptions"
          :series="pieChart.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>

import VueApexCharts from 'vue-apexcharts'
import colors from '@/utils/colors'

export default {
  components: {
    VueApexCharts
  },
  props: {
    option: {
      type: [Object],
      required: true,
    },
    title: {
      type: String,
      default: '',
    }
  },
  computed: {
    pieChart() {
      return {
        series: this.option.series,
        chartOptions: {
          labels: this.option.labels,
          colors,
          tooltip: {
            y: {
              formatter(value) {
                return `${value.toFixed(0)}€`;
              }
            }
          },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        }
      }
    }
  },
}
</script>
